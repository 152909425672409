import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from "@mui/material";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { useCallback, useEffect, useState } from "react";

import { localDB } from "../database/localDB";
import { downloadFileRequest } from "../networkUtils/request";
import { LocalLessonItemDoc } from "../database/localDB";
import { MarkdownNotesEditable } from "./MarkdownNotesEditable";
import { MarkdownNotesReadOnly } from "./MarkdownNotesReadOnly";
import { TNode } from "@udecode/plate";
import { checkLessonUnlocked } from "../database/remoteDB";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, User } from "firebase/auth";
import { Button, Card, Dialog } from "ui-neumorphism";

import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getLocalLessons } from "../database/mergedDB";
import { useTranslation } from "react-i18next";
import { FREE_LESSONS } from "../consts";

const PHONE_CONTACT = "+261 34 63 096 36";

interface Props {
  heading: string;
  data: LocalLessonItemDoc[];
  playRec: (lesson: LessonListItemData) => void;
  playingRecId: string;
}

interface DownloadInfo {
  name: string;
  progress: number | string;
}

interface LessonListItemData extends LocalLessonItemDoc {
  isPlaying: boolean;
}

export default function LessonList({
  heading,
  data,
  playRec,
  playingRecId,
}: Props) {
  const [t] = useTranslation("common");
  const PREPARING_DOWNLOAD = "preparing...";
  const [user, error] = useAuthState(getAuth());
  error && console.log("error::::::", error);
  const [mappedData, setMappedData] = useState<LessonListItemData[]>([]);
  const [downloadInfo, setDownloadInfo] = useState<DownloadInfo>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [lessonIdToDownload, setLessonIdToDownload] = useState<string>("");
  const [showBuyLessonDialog, setShowBuyLessonDialog] =
    useState<boolean>(false);
  // store completed status in lDB map over and add info

  const mapData = useCallback(
    async (data: LocalLessonItemDoc[]) => {
      const mapped = await Promise.all(
        data.map(async (item, index) => {
          const lessonFromIDB = await localDB.lessons.get({
            title: item.title,
          });
          // console.log({ lessonFromIDB });
          console.log("lessonFromIDB::::::", lessonFromIDB);
          const hasRecording = Boolean(lessonFromIDB?.recording?.size);

          const mappedItem: LessonListItemData = {
            ...item,
            // TODO implement completed when whole was heard
            completed: index === 0,
            availableOffline: hasRecording,
            isPlaying: item.recId === playingRecId,
            notesUser: item.notesUser,
          };
          return mappedItem;
        })
      );

      console.log({ mapped }, "mappedmappedmappedmapped");

      setMappedData(mapped);
    },
    [playingRecId]
  );

  useEffect(() => {
    if (!isDownloading) {
      console.log("map data effect");

      mapData(data);
    }
  }, [data, playingRecId, isDownloading, mapData]);

  const onProgress = (dI: DownloadInfo) => {
    setDownloadInfo(dI);
  };
  const checkHasAccess = async (lessonIdToCheck: string): Promise<boolean> => {
    console.log(
      ":::::::::::: file:LessonList.tsx ->>>> lessonIdToCheck:",
      lessonIdToCheck
    );

    if (FREE_LESSONS.includes(lessonIdToCheck)) {
      console.log(
        ":::::::::::: file:LessonList.tsx ->>>> FREE_LESSONS.includes( lessonIdToCheck ):",
        FREE_LESSONS.includes(lessonIdToCheck)
      );
      return true;
    }
    setIsDownloading(true);
    setDownloadInfo({ name: "Checking your information ...", progress: 0 });
    console.log("LessonList.tsx user::::", user);
    const hasAccess = await checkLessonUnlocked(
      lessonIdToCheck,
      user?.uid || ""
    );
    console.log("hasAccess::::::", hasAccess);
    return hasAccess;
  };

  const downloadAndSave = async (id: string) => {
    console.log("downloadAndSave::::::");
    setLessonIdToDownload(id);
    const allow = await checkHasAccess(id);
    console.log("allow::::::", allow);
    if (!allow) {
      console.log("allow::PERMISION REQUIRED::::::::::");

      setShowBuyLessonDialog(true);
      setIsDownloading(false);
      return;
    }

    setIsDownloading(true);
    const lesson = mappedData.find((d) => d.id === id);

    const name: string = lesson?.title || "";
    const recId: string = lesson?.recId || "";

    onProgress({ name, progress: PREPARING_DOWNLOAD });

    const handleProgress = (progress: number) => onProgress({ name, progress });
    const idToken = (await user?.getIdToken()) || "";
    console.log("idToken::::::", idToken);
    downloadFileRequest(idToken, recId, id, handleProgress)
      .then(async (response: any) => {
        console.log("response::DOWNLOAD::::", response);
        const updatedLessonWithRecording = await localDB.lessons.update(id, {
          recording: response,
        });
        console.log(
          "updatedLessonWithRecording::::::",
          updatedLessonWithRecording
        );

        setIsDownloading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDownloading(false);
      });
  };
  const handlePlayRec = async (row: LessonListItemData) => {
    // ///temp check implement BEMALEN
    // // use downloadAndSave + check checkHasAccess
    // try {
    //     const idToken = await user?.getIdToken()
    //     console.log('idToken::::::', idToken);
    //     downloadLesson(idToken || '', row.recId)
    // } catch (errorDownloadLesson) {
    //     console.log('errorDownloadLesson::::::', errorDownloadLesson);
    // }
    console.log("row::::handlePlayRec::", row);
    if (!row.availableOffline) {
      await downloadAndSave(row.id);
    }
    playRec(row);
  };

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleExpand = async (panel: string) => {
    setExpanded((prev) => (panel === prev ? "" : panel));
    const mostRecent = await getLocalLessons();
    mapData(mostRecent);
  };
  const onChangeEditNotes = async (lessonId: string, value: TNode[]) => {
    const updatedLessonWithRecording = await localDB.lessons.update(lessonId, {
      notesUser: value,
    });
    // debugger
    console.log("lessonId::::::", lessonId);
    console.log("updatedLessonWithRecording::::::", updatedLessonWithRecording);
    console.log("value::::::", value);
  };
  console.log({ mappedData }, "LessonList", playingRecId);
  console.log("isDownloading::::::", isDownloading);
  const isPreparingDownload = downloadInfo?.progress === PREPARING_DOWNLOAD;

  return (
    <>
      {isDownloading && isPreparingDownload && (
        <CircularProgress size={180} color="success" />
      )}
      {isDownloading && !isPreparingDownload && (
        <CircularProgressWithLabel value={Number(downloadInfo?.progress)} />
      )}
      {isDownloading && (
        <Typography
          variant="h6"
          color={"azure"}
          component="div"
          sx={{ flexGrow: 1 }}
        >
          {`downloading ${downloadInfo?.name}`}
        </Typography>
      )}
      <Typography sx={{ flexGrow: 1 }} color={"gray"}>
        {heading.toLocaleUpperCase()}
      </Typography>
      {mappedData.map((row) => (
        <React.Fragment key={row.title}>
          <Card
            style={{
              minWidth: "90%",
              display: "flex",
              padding: "9px",
              margin: "8px",
              justifyContent: "space-around",
              /* align-content: flex-start; */
              /* justify-content: flex-end; */
              alignItems: "center",
              position: "relative", // Add relative positioning
            }}
          >
            {FREE_LESSONS.includes(row.id) && (
              <div
                style={{
                  position: "absolute",
                  top: 16,
                  left: 8,
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  transform: "scale(1) translate(-50%, -60%) rotate(-45deg)",
                  backgroundColor: "#2e7d32",
                  borderRadius: "4px",
                  padding: "2px 4px",
                }}
              >
                <Typography
                  variant="caption"
                  style={{
                    color: "white",
                    fontSize: "0.75rem",
                    fontWeight: "bold",
                    lineHeight: 1,
                  }}
                >
                  {t("free")}
                </Typography>
              </div>
            )}

            {/* Rest of the row content */}
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              {row.title}
            </div>

            <Button
              rounded
              style={{ textAlign: "right" }}
              onClick={() => handleExpand(row.id)}
            >
              {/* <Typography variant="overline" component="div" sx={{ flexGrow: 1 }}> */}
              {/* Notes {'\u00A0'} */}
              {/* </Typography>  */}
              <ChromeReaderModeIcon />
            </Button>

            <Button
              rounded
              style={{ textAlign: "center" }}
              onClick={() => !row.availableOffline && downloadAndSave(row.id)}
            >
              <OfflinePinIcon
                color={row.availableOffline ? "success" : "disabled"}
                fontSize={row.availableOffline ? "large" : "medium"}
              />
              <Typography
                variant={"caption"}
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {!row.availableOffline && t("download")}
              </Typography>
            </Button>

            <div style={{ textAlign: "center" }}>
              <MilitaryTechIcon
                color={row.completed ? "success" : "disabled"}
                fontSize={"large"}
              />
            </div>
            <Button
              rounded
              style={{ textAlign: "center" }}
              onClick={() => handlePlayRec(row)}
            >
              {!row.isPlaying ? (
                <PlayCircleFilledWhiteIcon
                  color={row.availableOffline ? "success" : "disabled"}
                  fontSize={"large"}
                />
              ) : (
                <PauseCircleIcon fontSize={"large"} />
              )}
            </Button>
          </Card>
          {expanded === row.id && (
            <AccordionDetails>
              <MarkdownNotesReadOnly
                id={`${row.title}-read-only`}
                value={row.notes}
              />
            </AccordionDetails>
          )}

          {expanded === row.id && (
            <Card
              style={{
                minWidth: "90%",
                padding: "9px",
                margin: "px",
                alignItems: "center",
              }}
            >
              <MarkdownNotesEditable
                id={`${row.title}-editable`}
                initialValue={row.notesUser}
                onChange={(value) => onChangeEditNotes(row.id, value)}
              />
            </Card>
          )}
        </React.Fragment>
      ))}

      <BuyLessonDialog
        user={user}
        open={showBuyLessonDialog}
        handleCloseModal={() => setShowBuyLessonDialog(false)}
        lessonId={lessonIdToDownload}
      />
    </>
  );
}

const BuyLessonDialog = ({
  user,
  open,
  handleCloseModal,
  lessonId,
}: {
  user: User | null | undefined;
  open: boolean;
  handleCloseModal: () => void;
  lessonId: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [t, i18n] = useTranslation("common");

  return (
    <Dialog visible={open} onClose={handleCloseModal}>
      <DialogTitle>{}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>{t("lesson_locked_buy")}</p>
          <br />
          <p>
            user ID: <b>{user?.uid}</b>
          </p>
          <p>
            user name: <b>{user?.displayName}</b>
          </p>
          <p>
            email: <b>{user?.email} </b>
          </p>
          <p>
            lessonID: <b>{lessonId} </b>
          </p>

          <br />
        </DialogContentText>
        <DialogContentText>
          <h4>Contact:</h4>
          <p>{PHONE_CONTACT}</p>
          <a
            href={"https://www.facebook.com/profile.php?id=100093386623113"}
            target={"_blank"}
            rel="noreferrer"
          >
            Facebook Malen
          </a>
          <a href="mailto:core.malen@gmail.com">core.malen@gmail.com </a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>{t("ok")}</Button>
      </DialogActions>
    </Dialog>
  );
};

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        size={180}
        variant="determinate"
        color={"success"}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" component="div" color="success">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};
